export const DropdownMultiSelect = {
    singleSelection: false,
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    enableSearchFilter: true,
    classes: 'myclass custom-class',
    badgeShowLimit: 1,
};

// @ts-ignore
export const DropdownMultiSelectWithoutSearch = {
    singleSelection: false,
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    classes: 'myclass custom-class',
    badgeShowLimit: 1,
    enableSearchFilter: false
};

export const DropdownSingleSelect = {
    singleSelection: true,
    enableSearchFilter: true,
};

export const DropdownSingleSelectWithoutSearch = {
    singleSelection: true,
    enableSearchFilter: false,
};
