import { Component, OnInit, Input } from '@angular/core';
import { ROUTES } from '../../../shared/models/sidemenu-model';
import { Router } from '@angular/router';

@Component({
    selector: 'app-sidenav',
    templateUrl: './sidenav.component.html',
    styleUrls: ['./sidenav.component.css']
})
export class SidenavComponent implements OnInit {

    menuItems: any[];
    toggle: boolean = false;

    constructor(private router: Router) {
    }

    ngOnInit() {
        this.menuItems = ROUTES.filter(menuItem => menuItem);
    }

    isMobileMenu() {
        if (window.innerWidth > 991) {
            return false;
        }
        return true;
    };

    openFirstChild(menuItem) {

        document.getElementsByClassName('collapse');

        if (menuItem.title == 'Poll') {
            menuItem.isExpanded = !menuItem.isExpanded;
            if (menuItem.isExpanded) {
                this.router.navigate(['/poll/list']);
            }
        }

        if (menuItem.title == 'Event') {
            menuItem.isExpanded = !menuItem.isExpanded;
            if (menuItem.isExpanded) {
                this.router.navigate(['/event/list']);
            }
        }
        if (menuItem.title == 'Challenge') {
            menuItem.isExpanded = !menuItem.isExpanded;
            if (menuItem.isExpanded) {
                this.router.navigate(['/challenge/list']);
            }
        }
        if (menuItem.title == 'Activity') {
            menuItem.isExpanded = !menuItem.isExpanded;
            if (menuItem.isExpanded) {
                this.router.navigate(['/activity/list']);
            }
        }

        if (menuItem.title == 'Settings') {
            menuItem.isExpanded = !menuItem.isExpanded;
            if (menuItem.isExpanded) {
                this.router.navigate(['/setting/profile-fields']);
            }
        }
    }

    makeSidenav(title) {
        let data = JSON.parse(localStorage.getItem('role'));
        if (data) {
            for (let ob in data) {
                if (data[ob].title === title) {
                    return true;
                }
            }
            return false;
        }
    }


}

