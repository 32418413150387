import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { filter } from 'rxjs/operators';
import { SwUpdate } from '@angular/service-worker';
import { Location } from '@angular/common';

import { RoutePartsService } from "./services/common/route-parts.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  appTitle = 'Awr Admin';
  pageTitle = '';

  constructor(private router: Router, private swUpdate: SwUpdate,
    private activeRoute: ActivatedRoute, private routePartsService: RoutePartsService,
    private title: Title, private _location: Location) {
  }

  ngOnInit() {
    this.changePageTitle();
    if (this.swUpdate.isEnabled) {
      this.swUpdate.available.subscribe(() => {
        if (confirm("New version available. Load New Version?")) {
          window.location.reload();
        }
      });
    }
  }

  changePageTitle() {
    this.router.events.pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((routeChange) => {
        var routeParts = this.routePartsService.generateRouteParts(this.activeRoute.snapshot);
        if (!routeParts.length)
          return this.title.setTitle(this.appTitle);
        // Extract title from parts;
        this.pageTitle = routeParts
          .reverse()
          .map((part) => part.title)
          .reduce((partA, partI) => { return `${partA} > ${partI}` });
        this.pageTitle = `${this.appTitle} | ` + this.pageTitle;
        this.title.setTitle(this.pageTitle);
      });
  }
}
