export const apiUrls = {
    login: '/admin/adminLogin',
    logout: '/admin/logout',

    uploadFile: '/admin/uploadFile',

    // admin dashboard url
    getDashboardData: '/admin/getDashboard',

    // admin members urls
    getAdminUsers: '/admin/getAdminMembers',
    addEditAdminUsers: '/admin/addEditAdminUser',
    blockUnblockAdminUsers: '/admin/blockAdminUser',

    // letter urls
    getEmpLetterListing: '/admin/empLetterListing',
    getEmployeeLetterType: '/admin/employeeLetterType',
    getBankLetterListing: '/admin/bankLetterListing',
    getBankingTypeList: '/admin/bankingTypeList',
    getConsulateListing: '/admin/consulateListing',
    upoadEmpLetterFile: '/admin/upoadEmpLetterFile',
    GetAdminLetter: '/EmpApp/api/AdminLetter?AnalysisId=',
    adminLetterDownload: '/admin/adminLetterDownload?analysisId=',

    // new urls
    getNewsCategories: '/admin/showAdminNewsCategories',
    updateNewsCategory: '/admin/updateNewsCategory',

    // user urls
    getMembers: '/admin/getMembers',
    getUserList: '/admin/userList',
    getFilterFields: '/admin/filterFields',

    getApiFields: '/admin/apiFields',
    updateUserProfileFields: '/admin/userProfileFieldsFilter',
    sendPushUrl: '/admin/sendPush',
    updateDataBase: '/users/updateUserDataInDb',
    getFollowerFollowing: '/admin/userFollowerFollowingList',

    // group urls
    getGroups: '/admin/getGroups',
    addEditGroup: '/admin/addEditGroup',
    getGroupFeed: '/admin/groupFeeds',
    getUserFeeds: '/admin/getFeeds',
    blockFeedOrComment: '/admin/blockFeedOrComment',
    getCommentReplies: '/admin/getCommentReplies',
    getFeedComments: '/admin/getFeedComments',
    getGroupMembersWithPagination: '/admin/getGroupMembersWithPagination',
    blockUnblockGroup: '/admin/blockGroups',
    getGroupsList: '/admin/getGroupsList',
    updateCategoryPriority: '/admin/updateCategoryOrder',


    //  poll urls
    getPoll: '/polls/getPollsList',
    addPoll: '/polls/createPolls',
    editPoll: '/polls/editPolls',
    getMembersListPoll: '/admin/getMembersList',
    getGroupsListPoll: '/admin/getGroupsList',
    blockUnblockPolls: '/polls/markBlockUnblockPolls',
    providePollAccess: '/admin/providePollAccess',
    dashboardData: '/polls/getDashboardData',
    votedUsers: '/polls/getAllUserForVotingFromAdmin',
    deletePoll: '/polls/deletePollAdmin',
    pollUsersAndGroups: '/polls/getPollUsersAndGroups',

    //    events
    getEventList: '/events/getEventListForAdmin',
    getEventParticipateUsers: '/events/getParticipateUserListForAdmin',
    provideEventUserAccess: '/events/provideEventUserAccess',
    getEventDashboard: '/events/getEventDashboard',
    eventUserAndGroupLists: '/events/eventUserAndGroupLists',

    userHealthData: '/admin/userHealthData',
    defaultValues: '/admin/defaultValues',

    createChallenge: '/admin/challenge',
    challengeCategory: '/admin/challengeCategory',
    challengeCategories: '/admin/challengeCategories',
    challenges: '/admin/challenges',
    blockUnblockChallenge: '/admin/markBlockUnblockChallenge',
    deleteChallenge: '/admin/deleteChallenge',
    deleteChallengeCategory: '/admin/deleteChallengeCategory',
    provideChallengeAccess: '/admin/provideChallengeAccess',
    getChallengeData: '/admin/getChallengeData',
    addVitalValue: '/admin/addVitalValue',
    listChallengeVitalValues: '/admin/listChallengeVitalValues',

    createActivity: '/admin/activity',
    activities: '/admin/activities',
    blockUnblockActivity: '/admin/markBlockUnblockActivity',
    deleteActivity: '/admin/deleteActivity',
    provideActivityAccess: '/admin/provideActivityAccess',
    getActivityData: '/admin/getActivityData',

    //Vaccination URLs
    addVaccinationDetails: '/admin/covidVaccinationDetail/upsertVaccine',
    getVaccinationList: '/admin/covidVaccination/list',

};

export const getUserProfileFieldApiKey: string = 'User Profile';
export const DefaultImageUrl: string = '/assets/images/image_placeholder.ebe9884b.jpg';
export const DefaultUserImageUrl: string = '/assets/images/user.jpg';

export const SuccessConstant = {

    DatebaseUpdatedSuccess: 'Database Update Successfully!',
    PushSuccess: 'Push sent successfully!',
    RoleUpdateSuccess: 'Role updated successfully!',
    RoleAssignedSuccess: 'Role assigned successfully!',
    FieldsUpdatedSuccess: 'Fields Updated Sucessfully!',
    NewsSuccess: 'News Updated Sucessfully!',
    prioritySuccess: 'Proirity Changed Sucessfully!',
    FileUpdateSuccess: 'File uploaded successfully!',
    GroupUpdatedSuccess: 'Group updated successfully',
    GroupAddesSuccess: 'Group added successfully',
    CommentUpdatedSuccess: 'Comment Updated Successfully!',
    PostUpdatedSuccess: 'Post Updated Successfully!',
    blockedSuccess: 'Blocked Successfully!',
    unblockedSuccess: 'Unblocked Successfully!',
    updatedSuccess: 'Updated Successfully!',
    fillAllFieldsError: 'Please fill all the required data!',
    addedSuccess: 'Added Successfully!',
    accessGrantedSuccess: 'Access Granted Successfully!',
    deleteSuccess: 'Delete Successfully!'

};

export const InfoConstant: any = {

    SelectRole: 'Please select at least one role!',
    SelectUser: 'Firstly select user!',
    ImageType: 'File is not a image.',
    SelectGroupMember: 'Firstly select group members!'

};

export const ConfirmationConstant = {

    UpdateDatabase: 'Are you sure you want to update database?',
    CommentBlock: 'Are you sure you want to block this comment?',
    CommentUnBlock: 'Are you sure you want to unblock this comment?',
    YesBlock: 'Yes, block it!',
    YesUnBlock: 'Yes, unblock it!',
    PostBlock: 'Are you sure you want to block this post?',
    PostUnBlock: 'Are you sure you want to unblock this post?',
    deletePollMsg: 'Are you sure you want to delete this poll?',
    deleteChallengeMsg: 'Are you sure you want to delete this challenge?',
    deleteCategoryMsg: 'Are you sure you want to delete this category?',
    challengeUnBlock: 'Are you sure you want to unblock this challenge?',
    challengeBlock: 'Are you sure you want to block this challenge?',
    YesDeletePool: 'Yes, delete it!',
    deleteActivityMsg: 'Are you sure you want to delete this activity?',

};

export const PermissionConstant = {
    Add: 'Add',
    Edit: 'Edit',
    Push: 'Push',
    Posts: 'Posts',
    Detail: 'Detail',
    Comments: 'Comments',
    Result: 'Result',
    Export: 'Export',
    View: 'View',
    Action: 'Action',


    UpdateDatabase: 'Update Database',
    AccessControl: 'Access Control',
    UsersControl: 'Users',
    SettingControl: 'Settings',
    ManageProfileControl: 'Manage Profile Fields',
    NewsControl: 'News',
    AppreciationControl: 'Appreciation',
    GroupControl: 'Group',
    PollControl: 'Polls List',
    pollAnalyticsControl: 'Analytics',
    adminDefaultsControl: 'Admin Defaults',
    challengeCategory: 'Challenge Category',
    ChallengeControl: 'Challenge List',
    activityControl: 'Activity List',

};
