import { CanActivate, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { UiService } from '../common/ui-service';

@Injectable()
export class AuthGuardService implements CanActivate {
    
    checkTokenOnly: boolean = false;
    constructor(protected router: Router, private uiService: UiService) { }

    canActivate(checkTokenOnly) {
        if (!localStorage.getItem('awr_token')) {
            localStorage.clear();
            return true;
        }
        this.router.navigate([this.uiService.getFirstRoute()]);
        return false;
    }

}