export const AllHeadingConstant: any = {

    // login page
    adminLogin: 'Admin Login',
    username: 'Username',
    password: 'Password',
    logIn: 'LOG IN',

    // user page
    user: 'User',
    users: 'Users',
    organisationName: 'Organisation Name',
    designation: 'Designation',
    grade: 'Grade',
    location: 'Location',
    filter: 'Filter',
    reset: 'Reset',
    selectAll: 'Select All',
    sendPush: 'Send Push',
    updateDatabase: 'Update Database',
    followers: 'Followers',
    following: 'Following',
    detail: 'Detail',
    shortName: 'Short Name',
    searchUser: 'Search User',

    // user send push
    push: 'Push',
    cancel: 'Cancel',
    send: 'Send',
    message: 'Message',

    // letters page
    letters: 'Letters',
    bankLetter: 'Bank Letter',
    consulateLetter: 'Consulate Letter',
    otherLetter: 'Other letter',
    upload: 'Upload',
    attachmentToUpload: 'Attachment to upload',
    attachment: 'Attachment',
    letterType: 'Letter Type',
    accountNumber: 'Account Number',
    loanAmount: 'Loan Amount',
    bankName: 'Bank Name',
    createdDate: 'Created Date',
    startDate: 'Start Date',
    requiredDate: 'Required Date',
    comments: 'Comments',
    category: 'Category',
    status: 'Status',
    approvalPendingFrom: 'Approval Pending From',
    downloadFile: 'Download File',
    uploadFile: 'Upload File',
    transactionId: 'Transaction Id',
    letterLanguage: 'Letter Language',
    addressedTo: 'Addressed To',
    dateRequired: 'Date Required',
    endDateofTravel: 'End Date of Travel',
    startDateofTravel: 'Start Date of Travel',

    // header
    welcome: 'Welcome',
    logout: 'Log Out',

    // Dashboard
    dashboard: 'Dashboard',
    feedCount: 'Feed Count',
    likesCount: 'Likes Count',
    selectDateRange: 'Select Date Range',
    shareCount: 'Share Count',
    commentCount: 'Comment Count',
    onetoOneChatCount: 'One to One Chat Count',
    groupChatCount: 'Group Chat Count',
    chat: 'Chat',
    healthAndWellness: 'Health And Wellness',

    // Access Control
    accessControl: 'Access Control',
    addUser: 'Add User',
    control: 'Control',

    // post page
    posts: 'Posts',
    postMessage: 'Post Message',
    upvotes: 'Upvotes',
    createdBy: 'Created By',
    createdAt: 'Created At',

    // comment page
    video: 'Video',
    postImagesVideos: 'Post Images/Videos',
    na: 'NA',

    // event page
    eventDetails: 'Event Details',
    eventName: 'Event Name',
    endDate: 'End Date',
    participationDateTime: 'Participation Date Time',
    publishDate: 'Publish Date',
    shareWith: 'Share With',
    group: 'Group',
    timeline: 'Timeline',
    coming: 'Coming',
    notSure: 'Not Sure',
    groups: 'Groups',
    groupImage: 'Group Image',
    profilePic: 'Profile Pic',
    events: 'Events',
    totalEvents: 'Total Events',
    unpublishedEvents: 'Unpublished Events',
    publishedEvents: 'Published Events',
    ongoingEvents: 'Ongoing Events',
    upcomingEvents: 'Upcoming Events',
    pastEvents: 'Past Events',
    eventState: 'Event State',
    eventAddMembersAccess: 'Event Add Members Access',
    updateAccess: 'Update Access',

    // poll page
    poll: 'Poll',
    polls: 'Polls',
    addPoll: 'Add Poll',
    totalPolls: 'Total Polls',
    adminPolls: 'Admin Polls',
    userPolls: 'User Polls',
    totalOngoingPolls: 'Total Ongoing Polls',
    totalUpcomingPolls: 'Total Upcoming Polls',
    totalPastPolls: 'Total Past Polls',
    startDateRange: 'Start Date Range',
    createdByUser: 'Created By User',
    pollState: 'Poll State',
    all: 'All',
    exportCSV: 'Export CSV',
    questions: 'Questions',
    pollName: 'Poll Name',
    deleteQuestion: 'Delete Question',
    question: 'Question',
    pollQuestion: 'poll question',
    answersType: 'Answers Type',
    textAnswers: 'Text Answers',
    imageAnswers: 'Image Answers',
    pollOptions: 'Poll Options',
    pleaseAddMinimumTwoAnswerOptions: 'Please add minimum two answer options',
    pleaseAddAnswerOptions: 'Please add answer options',
    addOption: 'Add Option',
    addImageOption: 'Add Image Option',
    addQuestion: 'Add Question',
    asignTo: 'Assign to',
    chooseUsers: 'Choose Users',
    usersSelected: 'Users Selected',
    pleaseChooseUser: 'Please choose user',
    chooseGroups: 'Choose Groups',
    groupsSelected: 'Groups Selected',
    pleaseChooseGroup: 'Please choose group.',

    // group
    addGroup: 'Add Group',
    type: 'Type',
    privacyType: 'Privacy Type',
    onlyAdminCanPost: 'Only Admin Can Post',
    totalAdminMembers: 'Total Admin Members',
    totalSelectedMembers: 'Total Selected Members',
    imageName: 'Image Name',
    groupDescription: 'Group Description',
    groupMembers: 'Group Members',
    groupAdmins: 'Group Admins',
    membersSelected: 'Members Selected',
    searchUsers: 'Search Users',
    unSelectAll: 'UnSelect All',
    groupDetail: 'Group Detail',
    viewPosts: 'View Posts',
    editGroup: 'Edit Group',
    adminMembers: 'Admin Members',

    // health kit
    healthKit: 'Health And Wellness',
    vital: 'Vital',
    unit: 'Unit',
    totalSteps: 'Total Value',
    totalDistance: 'Total Distance KM(s)',
    totalCalories: 'Total Calories KCAL(s)',
    totalWalkTime: 'Total WalkTime MIN(s)',
    vitalInfo: 'Vital Info',
    startTime: 'Start Time',
    endTime: 'End Time',
    steps: 'Steps',
    walkTime: 'Walk Time MIN(s)',
    distance: 'Distance KM(s)',
    calories: 'Calories KCAL(s)',
    value: 'Value',
    weight: 'Weight (KG)',
    heartRate: 'Heart Rate (BPM)',
    looseWeight: 'Loose Weight',

    // admin defauls
    adminDefaults: 'Admin Defaults',
    dailyRequiredSteps: 'Daily Required Steps',
    updatedAdminDeafults: 'Updated Admin Deafults',

    // challenge
    challenge: 'Challenge',
    challenges: 'Challenges',
    challengeType: 'Challenge Type',
    draft: 'Draft',
    description: 'Description',
    maxPariticipantsPossible: 'Max Pariticipants',
    challengeCreateAccess: 'Challenge Create Access',
    addChallenge: 'Add Challenge',
    totalChallenge: 'Total Challenge',
    adminChallenge: 'Admin Challenge',
    userChallenge: 'User Challenge',
    totalOngoingChallenge: 'Total Ongoing Challenge',
    totalUpcomingChallenge: 'Total Upcoming Challenge',
    totalPastChallenge: 'Total Past Challenge',
    challengeCategory: 'Challenge Category',
    addCategory: 'Add Category',
    totalParticipants: 'Total Participants',
    dateRange: 'Date Range',
    challengeState: 'Challenge State',
    invitedUser: 'Invited User',
    invitedGroup: 'Invited Group',
    participants: 'Participants',
    challengeDetails: 'Challenge Details',
    challengeValue: 'Challenge Value',
    challengeLocation: 'Challenge Location',
    challengeFrom: 'Challenge From',
    challengeStartDate: 'Challenge Start Date',
    challengeEndDate: 'Challenge End Date',
    inviteTo: 'Invite To',
    maxPariticipantsAllowed: 'Max Pariticipants Allowed',
    totalInvitedUser: 'Total Invited User',
    totalInvitedGroup: 'Total Invited Group',

    activity: 'Activity',
    activityCreateAccess: 'Activity Create Access',
    addActivity: 'Add Activity',
    activites: 'Activites',
    totalActivity: 'Total Activity',
    adminActivity: 'Admin Activity',
    userActivity: 'User Activity',
    totalPastActivity: 'Total Past Activity',
    totalOngoingActivity: 'Total Ongoing Activity',
    totalUpcomingActivity: 'Total Upcoming Activity',
    activityState: 'Activity State',

    activityValue: 'Activity Value',
    activityLocation: 'Activity Location',
    activityFrom: 'Activity From',
    activityStartDate: 'Activity Start Date',
    activityEndDate: 'Activity End Date',

    // common
    noRecord: 'No record found',
    search: 'Search',
    showing: 'Showing',
    to: 'to',
    of: 'of',
    on: 'on',
    srNo: 'Sr. No.',
    image: 'Image',
    name: 'Name',
    email: 'Email',
    back: 'Back',
    selectOption: 'Select Option',
    action: 'Action',
    save: 'Save',
    update: 'Update',
    priority: 'Priority',
    news: 'News',
    previous: 'Previous',
    next: 'Next',
    readMore: 'read more',
    delete: 'Delete',
    edit: 'Edit',
    inactive: 'Inactive',
    active: 'Active',
    pleaseEnter: 'Please Enter',
    pleaseChoose: 'Please Choose',
    pleaseSelect: 'Please Select',
    submit: 'Submit',
    clear: 'Clear',
    addMembers: 'Add Members',
    areRequired: 'are required',
    isRequired: 'is required',
    yes: 'Yes',
    no: 'No',
    private: 'Private',
    public: 'Public',
    date: 'Date',
    close: 'Close',
    addValue: 'Add Value',

    //VaccineDetails
    vaccinationDetails: 'Vaccination Details',
    oracleId: 'Oracle Id',
    userId: 'User Id',
    vaccine01Date: 'Vaccine01 Date',
    vaccine01City: 'Vaccine01 City', 
    vaccine01Center: 'Vaccine01 Center',
    vaccine01Country: 'Vaccine01 Country',
    vaccine01Name: 'Vaccine01 Name',
    vaccine02City: 'Vaccine02 City',
    vaccine02Center: 'Vaccine02 Center',
    vaccine02Country: 'Vaccine02 Country',
    vaccine02Date: 'Vaccine02 Date',
    vaccine02Name: 'Vaccine02 Name'
};