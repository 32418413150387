import {Component, OnInit, ChangeDetectorRef, ViewChild, ElementRef} from '@angular/core';

//services
import {PaginationControls} from '../../../shared/models/pagination-model';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {UiService} from '../../../services/common/ui-service';
import {HttpService} from '../../../services/http/http.service';

// models
import {DropdownMultiSelect, DropdownSingleSelect} from '../../../shared/models/dropdown-model';

// constant
import {apiUrls} from '../../../constant/app.constant';
import { AllHeadingConstant } from '../../../constant/heading.constant';

@Component({
    selector: 'app-select-users',
    templateUrl: './select-users.component.html',
    styleUrls: ['./select-users.component.css']
})
export class SelectUsersComponent implements OnInit {

    headingConstant = AllHeadingConstant;
    
    pageControl = new PaginationControls();
    filterData: any = [
        {key: 'orgName', value: [], count: 0},
        {key: 'designation', value: [], count: 0},
        {key: 'grade', value: [], count: 0},
        {key: 'location', value: [], count: 0}
    ];
    filters: any = {
        designation: [],
        orgName: [],
        grade: [],
        location: [],
        search: ''
    };
    allData: any = [];
    selectedMembers: any[] = [];
    allUser: any = [];
    dropdownGrade: any = DropdownMultiSelect;
    dropdownDesignation: any = DropdownSingleSelect;
    isSelectedAllCkeck = false;

    @ViewChild('selectAllCheck') selectAllCheck: ElementRef;

    constructor(private http: HttpService,
                public activeModal: NgbActiveModal, public uiService: UiService, private cdRef: ChangeDetectorRef) {
    }

    ngOnInit() {
        let self = this;
        this.filterData.forEach(function (value, i) {
            self.getFilterFields(i + 1);
        });
        this.getData();
    }

    getData() {
        let obj = this.filteData(true);
        this.http.postData(apiUrls.getMembers, obj)
            .subscribe(response => {
                this.allData = response.data.data;
                this.pageControl.count = response.data.count;
                this.pageControl.pageIndex = (this.pageControl.page - 1) * this.pageControl.limit;

                this.isSelectedAllCkeck = response.data.count === this.selectedMembers.length;
            }, error => {
                if (error.status == 401) {
                    this.activeModal.dismiss();
                }
            });
    }

    // make filter data
    filteData(skip?: boolean) {
        let obj = {};
        if (skip) {
            obj['limit'] = this.pageControl.limit;
            obj['skip'] = (this.pageControl.page - 1) * this.pageControl.limit;
        }
        if (this.filters['search']) {
            obj['search'] = this.filters['search'];
        }
        if (this.filters['orgName'].length) {
            obj['orgName'] = [];
            this.filters.orgName.forEach(data => {
                obj['orgName'].push(data.id);
            });
        }
        if (this.filters['designation'].length) {
            obj['designation'] = this.filters['designation'][0].id;
        }
        if (this.filters['location'].length) {
            obj['location'] = this.filters['location'][0].id;
        }
        if (this.filters['grade'].length) {
            obj['grade'] = [];
            this.filters.grade.forEach(data => {
                obj['grade'].push(data.id);
            });
        }
        return obj;
    }

    // for selecting/unselecting single user
    selectUnselectSingleUser(data) {
        if (this.selectedMembers.findIndex(x => x._id == data._id) > -1) {
            this.selectedMembers.splice(this.selectedMembers.findIndex(x => x._id == data._id), 1);
        } else {
            this.selectedMembers.push({_id: data._id, firstName: data.firstName, lastName: data.lastName});
        }
    }

    // for selecting/unselecting all user
    selectUnselectAllUser(value?) {
        this.isSelectedAllCkeck = value;
        if (!value) {
            this.selectedMembers = [];
        } else {
            this.getAllSelectedUser();
        }
    }

    // get user _id and shortName
    getAllSelectedUser() {
        let obj = this.filteData();
        this.http.postData(apiUrls.getMembers, obj)
            .subscribe(response => {
                this.selectedMembers = [];
                this.allUser = response.data.data;
                this.selectedMembers = response.data.data;
                this.isSelectedAllCkeck = true;
            }, error => {
                this.isSelectedAllCkeck = false;
                if (error.status == 401) {
                    this.activeModal.dismiss();
                }
            });
    }

    // for selecting/unselecting listed user
    selectUnselectListUser(value) {
        let self = this;
        for (let data in self.allData) {
            const index = this.selectedMembers.findIndex(x => x._id == self.allData[data]._id);
            if (!value) {
                if (index > -1) {
                    this.selectedMembers.splice(index, 1);
                }
            } else {
                if (index == -1) {
                    this.selectedMembers.push({_id: self.allData[data]._id, shortName: self.allData[data].shortName});
                }
            }
        }
    }

    checkSelectedUser(_id?: string) {
        let self = this;
        if (self.allData.length) {
            if (_id) {
                return this.selectedMembers.findIndex(x => x._id == _id) > -1 ? true : false;
            } else {
                for (let data in self.allData) {
                    const index = this.selectedMembers.findIndex(x => x._id == self.allData[data]._id);
                    if (index == -1) {
                        return false;
                    }
                }
                return true;
            }
        }
        return false;
    }

    ngAfterViewChecked() {
        this.cdRef.detectChanges();
    }

    clearData() {
        this.selectedMembers = [];
        this.isSelectedAllCkeck = false;
        this.http.selectedUser.next(this.selectedMembers);
        // this.activeModal.dismiss();
    }

    submitForm() {
        this.http.selectedUser.next(this.selectedMembers);
        this.activeModal.dismiss();
    }

    getFilterFields(index, value?: string) {
        let self = this;
        let obj = this.getFilterObj(index, value);
        this.http.postData(apiUrls.getFilterFields, obj, false, true)
            .subscribe(response => {
                self.filterData[index - 1].value = [];
                response.data.list.forEach(function (value, i) {
                    self.filterData[index - 1].value.push({
                        'id': value[self.filterData[index - 1].key],
                        'itemName': value[self.filterData[index - 1].key]
                    });
                });
            }, error => {
                if (error.status == 401) {
                    this.activeModal.dismiss();
                }
            });
    }

    getFilterObj(type, value) {
        let obj = {
            'filterKey': type.toString(),
            'skip': '0',
            'limit': '50'
        };
        if (value) {
            obj['search'] = value;
        }
        return obj;
    }

    loadMore(value, index, keyname) {
        if (value == 'more') {
            let length = this.filterData[index - 1].value.length;
            this.filters[keyname] = this.filterData[index - 1].value[length - 1][keyname];
            this.getFilterFields(index, length);
        }
    }

    clearFields() {
        this.filters.search = '';
        this.filters.designation = '';
        this.filters.grade = '';
        this.filters.orgName = '';
        this.filters.location = '';
        this.pageControl.page = 1;
        this.getData();
    }

}
