import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { RoutePartsService } from '../../services/common/route-parts.service';
import { ROUTES } from '../../shared/models/sidemenu-model';

declare var jQuery: any;
declare var $: any;
declare var toastr: any;

@Injectable()
export class UiService {

    private menus = ROUTES;

    constructor(private _location: Location, private router: Router,
        private routePartsService: RoutePartsService, private activeRoute: ActivatedRoute) {
    }

    goBack() {
        this._location.back();
    }

    cal(page, limit, count) {
        if (page * limit <= count) {
            return page * limit;
        } else {
            return count;
        }
    }

    calSkip(payload) {
        return payload.page * payload.limit;
    }

    // clear local storage
    clearLocalStorage() {
        localStorage.removeItem('awr_token');
        localStorage.removeItem('userId');
        localStorage.removeItem('role');
        this.router.navigate(['/signin']);
    }

    getFirstRoute() {
        let data = JSON.parse(localStorage.getItem('role'));
        if (data) {
            for (let ob in this.menus) {
                if (this.menus[ob].title === data[0].title) {
                    return this.menus[ob].path;
                }
            }
        }
        return '/users';
    }

    checkPermissions(url) {
        let data = JSON.parse(localStorage.getItem('role'));
        for (let ob in this.menus) {
            if (this.menus[ob].path === url) {
                if (data) {
                    for (let obb in data) {
                        if (data[obb].item === this.menus[ob].title) {
                            return true;
                        }
                    }
                }
            }
        }
        return false;
    }

    getPermissions(title, value) {
        let data = JSON.parse(localStorage.getItem('role'));
        for (let ob in data) {

            if (data[ob].title === title && title != 'Settings') {
                if (value) {
                    for (let obb in data[ob].data) {
                        if (data[ob].data[obb] === value) {
                            return true;
                        }
                    }
                    return false;
                }
                return true;
            }
        }
        return false;
    }

    getPermissionSubmenu(title, subTitle?: string, value?: string) {
        let data = JSON.parse(localStorage.getItem('role'));
        for (let ob in data) {
            if (data[ob].title === title && value) {
                for (let obb in data[ob].data) {
                    if (data[ob].data[obb].title === subTitle) {
                        for (let obbb in data[ob].data[obb].data) {
                            if (data[ob].data[obb].data[obbb] === value) {
                                return true;
                            }
                        }   
                    }
                }
            } else if (data[ob].title === title) {
                return true;
            }
        }
        ;
        return false;
    }

    getRouterParts() {
        var routeParts = this.routePartsService.generateRouteParts(this.activeRoute.snapshot);
        // generate url from parts
        return routeParts.reverse().map((item, i) => {
            item.breadcrumb = this.parseText(item);
            item.urlSegments.forEach((urlSegment, j) => {
                if (j === 0) {
                    return item.url = `${urlSegment.path}`;
                }
                item.url += `/${urlSegment.path}`;
            });
            if (i === 0) {
                return item;
            }
            // prepend previous part to current part
            item.url = `${routeParts[i - 1].url}/${item.url}`;
            return item;
        });
    }

    private parseText(part) {
        part.breadcrumb = part.breadcrumb.replace(/{{([^{}]*)}}/g, function (a, b) {
            var r = part.params[b];
            return typeof r === 'string' ? r : a;
        });
        return part.breadcrumb;
    }

    isString(ob) {
        return typeof ob === 'string';
    }

    firstLetterCapitalise(value?: string) {
        return value.charAt(0).toUpperCase() + value.slice(1);
    }
}
