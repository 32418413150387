import { environment } from './../../../environments/environment';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams, HttpHeaders } from "@angular/common/http";
import { map } from 'rxjs/operators';
import { ResponseContentType } from "@angular/http/index";

// services
import { LoaderService } from '../common/loader-service';

import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HttpService {

  selectedUser = new BehaviorSubject([]);
  selectedGroup = new BehaviorSubject([]);

  constructor(
    private http: HttpClient,
    private loader: LoaderService
  ) { }

  /**
   * @method (get http request)
   * @param url (api url)
   * @param data (all search params)
   * @param backGroundUrl (don't want to show loader for any request pass true)
   */
  getData(url: string, data?: any, backGroundUrl?: boolean): Observable<any> {
    const searchParams = this.appendParams(data);
    let apiUrl = `${environment.apiBaseUrl}${url}`;
    if (backGroundUrl) {
      this.loader.backGroundUrls.push(apiUrl)
    }
    return this.http.get(apiUrl, { params: searchParams })
      .pipe(map((response: any) => {
        return response;
      }))
  }

  /**
   * @method (post http request)
   * @param url (api url)
   * @param data (data which we have to pass to server)
   * @param formData 
   */
  postData(url: string, data, formData?: boolean, backGroundUrl?: boolean) {
    let apiUrl = `${environment.apiBaseUrl}${url}`;
    let postData = !formData ? data : this.appendFormData(data);
    if (backGroundUrl) {
      this.loader.backGroundUrls.push(apiUrl);
    }
    return this.http.post(apiUrl, postData)
      .pipe(map((response: any) => {
        return response;
      }))
  }

  /**
   * @method (put http request)
   * @param url (api url)
   * @param data (data which we have to pass to server)
   * @param formData 
   */
  putData(url: string, data, formData?: boolean) {
    let apiUrl = `${environment.apiBaseUrl}${url}`;
    let postData = !formData ? data : this.appendFormData(data);
    return this.http.put(apiUrl, postData)
      .pipe(map((response: any) => {
        return response;
      }))
  }

  /**
   * @method (patch http request)
   * @param url (api url)
   * @param data (data which we have to pass to server)
   * @param formData 
   */
  patchData(url: string, data, formData?: boolean) {
    let apiUrl = `${environment.apiBaseUrl}${url}`;
    let postData = !formData ? data : this.appendFormData(data);
    return this.http.patch(apiUrl, postData)
      .pipe(map((response: any) => {
        return response;
      }))
  }

  /**
   * @method (delete http request)
   * @param url (api url)
   * @param data (data which we have to pass to server)
   * @param formData 
   */
  deleteData(url: string, data?: any, formData?: boolean) {
    let apiUrl = `${environment.apiBaseUrl}${url}`;
    let postData = !formData ? data : this.appendFormData(data);
    return this.http.delete(apiUrl, postData)
      .pipe(map((response: any) => {
        return response;
      }))
  }

  /**
   * @method (append formdata)
   * @param myFormData (pass which you want to make formdata type)
   */
  appendFormData(myFormData: { [x: string]: any; }): FormData {
    let fd = new FormData();
    for (let key in myFormData) {
      fd.append(key, myFormData[key]);
    }
    return fd;
  }

  /**
   * @method (append params)
   * @param myParams (pass data which you want to make http params)
   */
  appendParams(myParams: { [x: string]: any; }): HttpParams {
    let params = new HttpParams();
    for (let key in myParams) {
      // if (data[key] || key == "skip")
      params = params.append(key,myParams[key]);
    }
    return params;
  }

}
