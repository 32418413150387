import {Component, OnInit, OnDestroy, ChangeDetectorRef} from '@angular/core';
import {Subscription} from 'rxjs';
import {LoaderService} from '../../../services/common/loader-service';
import {LoaderState} from '../../models/loader-state';

@Component({
    selector: 'angular-loader',
    templateUrl: 'loader.component.html',
    styleUrls: ['loader.component.css']
})

export class LoaderComponent implements OnInit, OnDestroy {
    show = false;
    private subscription: Subscription;

    constructor(private loaderService: LoaderService, private changeDetectorRef: ChangeDetectorRef) {
    }

    ngOnInit() {
        this.subscription = this.loaderService.loaderState
            .subscribe((state: LoaderState) => {
                this.show = state.show;
                this.changeDetectorRef.detectChanges();
            });
    }

    // tslint:disable-next-line:use-life-cycle-interface
    ngOnDestroy() {
        this.subscription.unsubscribe();
        this.changeDetectorRef.detach();
    }
}
