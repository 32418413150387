import {Component, OnInit, ChangeDetectorRef} from '@angular/core';

//services
import {PaginationControls} from '../../../shared/models/pagination-model';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {UiService} from '../../../services/common/ui-service';
import {HttpService} from '../../../services/http/http.service';

// models
import {DropdownMultiSelect, DropdownSingleSelect} from '../../../shared/models/dropdown-model';

// constant
import {apiUrls} from '../../../constant/app.constant';
import { AllHeadingConstant } from '../../../constant/heading.constant';

@Component({
    selector: 'app-select-groups',
    templateUrl: './select-groups.component.html',
    styleUrls: ['./select-groups.component.css']
})
export class SelectGroupsComponent implements OnInit {

    headingConstant = AllHeadingConstant;
    pageControl = new PaginationControls();
    filterData: any = [];
    filters: any = {
        search: ''
    };
    allData: any = [];
    selectedGroup: any[] = [];
    allUser: any = [];
    isSelectedAllCkeck = false;

    constructor(
        private http: HttpService,
        public activeModal: NgbActiveModal,
        public uiService: UiService,
        private cdRef: ChangeDetectorRef
    ) {
    }

    ngOnInit() {
        let self = this;
        this.filterData.forEach(function (value, i) {
            self.getFilterFields(i + 1);
        });
        this.getData();
    }

    getData() {
        let obj = this.filteData(true);
        this.http.getData(apiUrls.getGroupsList, obj)
            .subscribe(response => {
                this.allData = response.data.data;
                this.pageControl.count = response.data.count;
                this.pageControl.pageIndex = (this.pageControl.page - 1) * this.pageControl.limit;
                this.isSelectedAllCkeck = response.data.count === this.selectedGroup.length;
            }, error => {
                if (error.status == 401) {
                    this.activeModal.dismiss();
                }
            });
    }

    // make filter data
    filteData(skip?: boolean) {
        let obj = {};
        if (skip) {
            obj['limit'] = this.pageControl.limit;
            obj['skip'] = (this.pageControl.page - 1) * this.pageControl.limit;
        }
        if (this.filters['search']) {
            obj['search'] = this.filters['search'];
        }
        return obj;
    }

    // for selecting/unselecting single user
    selectUnselectSingleUser(data) {
        if (this.selectedGroup.findIndex(x => x._id == data._id) > -1) {
            this.selectedGroup.splice(this.selectedGroup.findIndex(x => x._id == data._id), 1);
        } else {
            this.selectedGroup.push({_id: data._id, groupName: data.groupName});
        }
    }

    // for selecting/unselecting all user
    selectUnselectAllUser(value) {
        if (!value) {
            this.selectedGroup = [];
        } else {
            this.getAllSelectedUser();
        }
    }

    // get user _id and shortName
    getAllSelectedUser() {
        let obj = this.filteData();
        this.http.getData(apiUrls.getGroupsListPoll, obj)
            .subscribe(response => {
                this.selectedGroup = [];
                this.allUser = response.data.data;
                this.selectedGroup = response.data.data;
                this.isSelectedAllCkeck = true;
            }, error => {
                this.isSelectedAllCkeck = true;
                if (error.status == 401) {
                    this.activeModal.dismiss();
                }
            });
    }

    // for selecting/unselecting listed user
    selectUnselectListUser(value) {
        let self = this;
        for (let data in self.allData) {
            const index = this.selectedGroup.findIndex(x => x._id == self.allData[data]._id);
            if (!value) {
                if (index > -1) {
                    this.selectedGroup.splice(index, 1);
                }
            } else {
                if (index == -1) {
                    this.selectedGroup.push({_id: self.allData[data]._id, shortName: self.allData[data].shortName});
                }
            }
        }
    }

    checkSelectedUser(_id?: string) {
        // let self = this;
        if (this.allData.length) {
            // this.selectedGroup = this.allData;
            if (_id && this.selectedGroup.length) {
                return this.selectedGroup.findIndex(x => x._id == _id) > -1 ? true : false;
            } else {
                for (let data in this.allData) {
                    // if (this.selectedGroup.length) {
                    const index = this.selectedGroup.findIndex(x => x._id == this.allData[data]._id);
                    if (index == -1) {
                        return false;
                    }
                    // }
                }
                return true;
            }
        }
        return false;
    }

    ngAfterViewChecked() {
        this.cdRef.detectChanges();
    }

    submitForm() {
        this.http.selectedGroup.next(this.selectedGroup);
        this.activeModal.dismiss();
    }

    getFilterFields(index, value?: string) {
        let self = this;
        let obj = this.getFilterObj(index, value);
        this.http.postData(apiUrls.getFilterFields, obj, false, true)
            .subscribe(response => {
                self.filterData[index - 1].value = [];
                response.data.list.forEach(function (value, i) {
                    self.filterData[index - 1].value.push({
                        'id': value[self.filterData[index - 1].key],
                        'itemName': value[self.filterData[index - 1].key]
                    });
                });
            }, error => {
                if (error.status == 401) {
                    this.activeModal.dismiss();
                }
            });
    }

    getFilterObj(type, value) {
        let obj = {
            'filterKey': type.toString(),
            'skip': '0',
            'limit': '50'
        };
        if (value) {
            obj['search'] = value;
        }
        return obj;
    }

    loadMore(value, index, keyname) {
        if (value == 'more') {
            let length = this.filterData[index - 1].value.length;
            this.filters[keyname] = this.filterData[index - 1].value[length - 1][keyname];
            this.getFilterFields(index, length);
        }
    }

    clearFields() {
        this.filters.search = '';
        this.pageControl.page = 1;
        this.getData();
    }

    clearData() {
        this.selectedGroup = [];
        this.isSelectedAllCkeck = false;
        this.http.selectedUser.next(this.selectedGroup);
        // this.activeModal.dismiss();
    }

}
