import { Component, OnInit } from '@angular/core';
import { AllHeadingConstant } from '../../../constant/heading.constant';

@Component({
  selector: 'app-no-record',
  templateUrl: './no-record.component.html',
  styleUrls: ['./no-record.component.css']
})
export class NoRecordComponent implements OnInit {

  headingConstant = AllHeadingConstant;

  constructor() { }

  ngOnInit() {
  }

}
