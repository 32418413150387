import { NgModule } from '@angular/core';

// module
import { HttpClientModule } from '@angular/common/http';
import { NgbModule, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { MatSlideToggleModule } from '@angular/material';
import { NgxPaginationModule } from 'ngx-pagination';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// toastr module
import { ToastrModule } from 'ngx-toastr';
import { BsDatepickerModule, BsDropdownModule, ProgressbarModule } from 'ngx-bootstrap';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { FilterPipe } from '../pipes/filter.pipe';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';

import { TrimValueAccessorModule } from 'ng-trim-value-accessor';
import { SelectUsersComponent } from '../../secure-pages/group/select-users/select-users.component';
import { AdminMembersComponent } from '../../secure-pages/group/admin-members/admin-members.component';
import { CommonModule } from '@angular/common';
import { SelectGroupsComponent } from '../../secure-pages/group/select-groups/select-groups.component';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { MyDateRangePickerModule } from 'mydaterangepicker';
import { LightboxModule } from 'ngx-lightbox';
import { UtcDatePipe } from '../pipes/utc-date.pipe';
import { NoRecordComponent } from '../components/no-record/no-record.component';

import { ChartsModule } from 'ng2-charts';
import { ModalModule } from 'ngx-bootstrap/modal';

// directive
import { GoogleplaceDirective } from '../directives/googleplace.directive';
import { NumberOnlyDirective } from '../directives/number-only.directive';
import { AmountDirective } from '../directives/amount.directive';


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        MyDateRangePickerModule,
        ReactiveFormsModule,
        MatSlideToggleModule,
        ToastrModule.forRoot({
            tapToDismiss: true,
            positionClass: 'toast-top-right',
            timeOut: 3000,
            preventDuplicates: true,
            progressBar: true,
            progressAnimation: 'increasing',
            closeButton: true
        }),
        BsDatepickerModule.forRoot(),
        ButtonsModule.forRoot(),
        BsDropdownModule.forRoot(),
        PopoverModule.forRoot(),
        TooltipModule.forRoot(),
        TabsModule.forRoot(),
        AngularMultiSelectModule,
        TrimValueAccessorModule,
        NgbModule.forRoot(),
        NgxPaginationModule,
        ProgressbarModule.forRoot(),
        LightboxModule,
        ChartsModule,
        ModalModule.forRoot()
    ],
    exports: [
        MatSlideToggleModule,
        NgxPaginationModule,
        NgbModule,
        HttpClientModule,
        MyDateRangePickerModule,
        FormsModule,
        ReactiveFormsModule,
        BsDatepickerModule,
        BsDropdownModule,
        PopoverModule,
        FilterPipe,
        TooltipModule,
        TabsModule,
        AngularMultiSelectModule,
        TrimValueAccessorModule,
        ProgressbarModule,
        LightboxModule,
        UtcDatePipe,
        NoRecordComponent,
        ChartsModule,
        ModalModule,
        GoogleplaceDirective,
        NumberOnlyDirective,
        AmountDirective
    ],
    declarations: [
        FilterPipe,
        UtcDatePipe,
        SelectUsersComponent,
        AdminMembersComponent,
        SelectGroupsComponent,
        NoRecordComponent,
        GoogleplaceDirective,
        NumberOnlyDirective,
        AmountDirective
    ],
    entryComponents: [
        SelectUsersComponent,
        AdminMembersComponent,
        SelectGroupsComponent
    ],
    providers: [
        NgbActiveModal
    ]
})
export class SharedModulesModule {
}

