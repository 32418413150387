import { NgModule } from '@angular/core';

// common
import { UiService } from './common/ui-service';
import { LoaderService } from './common/loader-service';
import { RoutePartsService } from './common/route-parts.service';
import { SideBarService } from './common/side-bar.service';

// guards
import { LayoutGuardService } from './guard/layout-guard.service';
import { AuthGuardService } from './guard/auth-guard.service';

// interceptors
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthenticationInterceptor } from './authentication.interceptor';

// http
import { HttpService } from './http/http.service';

@NgModule({
    imports: [],
    exports: [],
    providers: [
        RoutePartsService,
        AuthGuardService,
        LoaderService,
        HttpService,
        LayoutGuardService,
        UiService,
        SideBarService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthenticationInterceptor,
            multi: true
        }
    ],
    declarations: [],
})
export class ServiceModule {

}
