import { CanActivate, Router, ActivatedRouteSnapshot, NavigationEnd } from '@angular/router';
import { Injectable } from '@angular/core';

@Injectable()
export class LayoutGuardService implements CanActivate {

    checkTokenOnly: boolean = false;

    constructor(protected router: Router) { }

    canActivate(route: ActivatedRouteSnapshot) {
        if (localStorage.getItem('awr_token')) {
            return true;
        }
        localStorage.clear();;
        this.router.navigate(['/signin']);
        return false;   
    }

}