import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
  window.console.log = function(){};
}

platformBrowserDynamic().bootstrapModule(AppModule).then(() => {

  const isSafari = () => {
    return navigator.userAgent.indexOf('Safari') !== -1;
  };

  if (isSafari()) {
  } else {

    if ('serviceWorker' in navigator && environment.production) {
      navigator.serviceWorker.register('/ngsw-worker.js');
    }

  }

}).catch(error => console.log());

