import { Routes } from '@angular/router';

// guards
import { AuthGuardService } from './services/guard/auth-guard.service';
import { LayoutGuardService } from './services/guard/layout-guard.service';

// components layout and session
import { SigninComponent } from './public-pages/signin/signin.component';
import { LayoutComponent } from './secure-pages/layout.component';

export const rootRouterConfig: Routes = [
  {
    path: '', redirectTo: 'signin', pathMatch: 'full'
  },
  {
    path: 'signin', component: SigninComponent, canActivate: [AuthGuardService],
    data: { title: 'Signin', breadcrumb: 'SIGNIN' }
  },
  {
    path: '',
    component: LayoutComponent,
    children: [
      {
        path: 'users', loadChildren: './secure-pages/users/users.module#UsersModule',
        data: { title: 'Users', breadcrumb: 'Users' },
        canActivate: [LayoutGuardService]
      },
      {
        path: 'letters', loadChildren: './secure-pages/letter/letter.module#LetterModule',
        data: { title: 'Letters', breadcrumb: 'Letters' },
        canActivate: [LayoutGuardService]
      },
      {
        path: 'group', loadChildren: './secure-pages/group/group.module#GroupModule',
        data: { title: 'Group', breadcrumb: 'Group' },
        canActivate: [LayoutGuardService]
      },
      {
        path: 'news', loadChildren: './secure-pages/news/news.module#NewsModule',
        data: { title: 'News', breadcrumb: 'News' },
        canActivate: [LayoutGuardService]
      },
      {
        path: 'poll', loadChildren: './secure-pages/polls/polls.module#PollsModule',
        data: { title: 'Poll', breadcrumb: 'Poll' },
        canActivate: [LayoutGuardService]
      },
      {
        path: 'event', loadChildren: './secure-pages/event/event.module#EventModule',
        data: { title: 'Event', breadcrumb: 'Event'},
        canActivate: [LayoutGuardService]
      },
      {
        path: 'setting', loadChildren: './secure-pages/setting/setting.module#SettingModule',
        data: { title: 'Settings', breadcrumb: 'Settings' },
        canActivate: [LayoutGuardService]
      },
      {
        path: 'access', loadChildren: './secure-pages/access-control/access-control.module#AccessControlModule',
        data: { title: 'Access Control', breadcrumb: 'Access Control' },
        canActivate: [LayoutGuardService]
      },
      {
        path: 'dashboard', loadChildren: './secure-pages/dashboard/dashboard.module#DashboardModule',
        data: { title: 'Dashboard', breadcrumb: 'Dashboard' },
        canActivate: [LayoutGuardService]
      },
      {
        path: 'health-kit', loadChildren: './secure-pages/user-healthkit/healthkit.module#HealthkitModule',
        data: { title: 'Health And Wellness', breadcrumb: 'Health And Wellness' },
        canActivate: [LayoutGuardService]
      },
      {
        path: 'admin-defaults', loadChildren: './secure-pages/admin-defaults/admin-defaults.module#AdminDefaultsModule',
        data: { title: 'Admin Defaults', breadcrumb: 'Admin Defaults' },
        canActivate: [LayoutGuardService]
      },
      {
        path: 'appreciation', loadChildren: './secure-pages/appreciation/appreciation.module#AppreciationModule',
        data: { title: 'Appreciation', breadcrumb: 'Appreciation' },
        canActivate: [LayoutGuardService]
      },
      {
        path: 'announcement', loadChildren: './secure-pages/announcement/announcement.module#AnnouncementModule',
        data: { title: 'Announcement', breadcrumb: 'Announcement' },
        canActivate: [LayoutGuardService]
      },
      {
        path: 'service-awards',loadChildren: './secure-pages/service-awards/service-awards.module#ServiceAwardsModule',
        data: { title: 'Service Awards', breadcrumb: 'Service Awards' },
        canActivate: [LayoutGuardService]
      },
      {
        path: 'points', loadChildren: './secure-pages/points/points.module#PointsModule',
        data: { title: 'Points', breadcrumb: 'Points' },
        canActivate: [LayoutGuardService]
      },
      {
        path: 'routes', loadChildren: './secure-pages/routes/routes.module#RoutesModule',
        data: { title: 'Routes', breadcrumb: 'Routes' },
        canActivate: [LayoutGuardService]
      },
      {
        path: 'challenge', loadChildren: './secure-pages/challenge/challenge.module#ChallengeModule',
        data: { title: 'Challenge', breadcrumb: 'Challenge' },
        canActivate: [LayoutGuardService]
      },
      {
        path: 'activity', loadChildren: './secure-pages/activity/activity.module#ActivityModule',
        data: { title: 'Activity', breadcrumb: 'Activity' },
        canActivate: [LayoutGuardService]
      },
      {
        path: 'vaccination-details', loadChildren: './secure-pages/vaccination-details/vaccination-details.module#VaccinationDetailsModule',
        data: { title: 'Vaccination Details', breadcrumb: 'Vaccination Details' },
        canActivate: [LayoutGuardService]
      }
    ]
  },
  {
    path: '**', component: SigninComponent, canActivate: [AuthGuardService],
    data: { title: 'Signin', breadcrumb: 'SIGNIN' }
  }
];

