import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {

  transform(items: any[], field: string, searchText: string): any[] {
    if (!items) return [];
    if (!searchText) return items;
    searchText = searchText.toLowerCase();
    let i = 0;
    return items.filter(data => {
      if (data[field])
        return data[field].toLowerCase().includes(searchText);
    });
  }

}
