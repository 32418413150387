import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule, ReactiveFormsModule, FormControl, Validators } from '@angular/forms';
import 'hammerjs';

// shared Components
import { LoaderComponent } from './shared/components/loader/loader.component';
import { HeaderComponent } from './shared/components/header/header.component';
import { SidenavComponent } from './shared/components/sidenav/sidenav.component';

// components
import { AppComponent } from './app.component';
import { SigninComponent } from './public-pages/signin/signin.component';
import { LayoutComponent } from './secure-pages/layout.component';

// shared modules
import { SharedModulesModule } from './shared/modules/shared-modules.module';
import { rootRouterConfig } from './app.routing';

// services
import { ServiceModule } from './services/service.module';

import { PopoverModule } from 'ngx-bootstrap/popover';

import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';

@NgModule({
  declarations: [
    AppComponent,
    SigninComponent,
    LayoutComponent,
    LoaderComponent,
    HeaderComponent,
    SidenavComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    SharedModulesModule,
    FormsModule,
    ReactiveFormsModule,
    ServiceModule,
    RouterModule.forRoot(rootRouterConfig, { scrollPositionRestoration: 'enabled', useHash: false}),
    PopoverModule.forRoot(),
    ServiceWorkerModule.register('/ngsw-worker.js', { enabled: environment.production })
  ],
  bootstrap: [AppComponent],
  entryComponents: []
})
export class AppModule { }
