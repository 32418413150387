// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: true,
  apiBaseUrl: 'https://engage-backendapi-xnvz5jnphq-ew.a.run.app',//'https://hrempapp.awrostamani.ae', // api path prod
  letterUrl: 'https://engage.awr-api.com', // letter path prod
  appName: 'empapp',
  auth: 'secure123empapp',
  imageUrl: 'https://ik.imagekit.io/ct2r6oxbcn/engageProd',
  laravelAdminPanelUrl:'https://engage-adminpanel-laravel-xnvz5jnphq-ew.a.run.app'
};

