import { Component, OnInit, ChangeDetectorRef } from '@angular/core';

//services
import { PaginationControls } from '../../../shared/models/pagination-model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UiService } from '../../../services/common/ui-service';
import { HttpService } from '../../../services/http/http.service';
import { AllHeadingConstant } from '../../../constant/heading.constant';

@Component({
  selector: 'app-admin-members',
  templateUrl: './admin-members.component.html',
  styleUrls: ['./admin-members.component.css']
})
export class AdminMembersComponent implements OnInit {

  headingConstant = AllHeadingConstant;
  pageControl = new PaginationControls();
  search: string = '';
  selectedMembers: any = [];
  adminMembers: any = [];

  constructor(private http: HttpService,
    public activeModal: NgbActiveModal, public uiService: UiService, private cdRef: ChangeDetectorRef) { }

  ngOnInit() {
    this.pageControl.count = this.selectedMembers.length;
  }

  // for selecting/unselecting single user
  selectUnselectSingleUser(data) {
    if (this.adminMembers.findIndex(x => x._id == data._id) > -1)
      this.adminMembers.splice(this.adminMembers.findIndex(x => x._id == data._id), 1)
    else
      this.adminMembers.push({ _id: data._id, firstName: data.firstName, lastName: data.lastName });
  }

  // for selecting/unselecting all user
  selectUnselectAllUser(value?: string) {
    if (!value)
      this.adminMembers = [].concat(this.selectedMembers);
    else
      this.adminMembers = [];
  }

  checkSelectedUser(_id?: string) {
    let self = this;
    if (self.selectedMembers.length) {
      if (_id)
        return this.adminMembers.findIndex(x => x._id == _id) > -1 ? true : false;
      else {
        for (let data in self.selectedMembers) {
          const index = this.adminMembers.findIndex(x => x._id == self.selectedMembers[data]._id)
          if (index == -1)
            return false;
        }
        return true;
      }
    } return false;
  }

  submitForm() {
    this.http.selectedUser.next(this.adminMembers);
    this.activeModal.dismiss();
  }

}
