import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';

// services
import { UiService } from '../../services/common/ui-service';
import { ToastrService } from 'ngx-toastr';
import { HttpService } from '../../services/http/http.service';

// constants
import { apiUrls } from '../../constant/app.constant';
import { AllHeadingConstant } from '../../constant/heading.constant';


@Component({
    selector: 'app-signin',
    templateUrl: './signin.component.html',
    styleUrls: ['./signin.component.css']
})
export class SigninComponent implements OnInit { 

    headingConstant = AllHeadingConstant;

    show: boolean = false;
    btnDisabled: boolean = false;
    form: FormGroup;

    constructor(private fb: FormBuilder, private http: HttpService,
        private router: Router, private uiService: UiService, private toastrService:ToastrService) {
        this.form = fb.group({
            'username': ['', Validators.compose([Validators.required])],
            'password': ['', Validators.compose([Validators.required])]
        });
    }

    ngOnInit() {}

    signIn(valid, value): void {
        this.show = true;
        if (valid) {
            this.btnDisabled = true;
            this.http.postData(apiUrls.login, value)
                .subscribe(response => {
                    localStorage.setItem('awr_token', response.data.accessToken);
                    localStorage.setItem('userId', response.data._id);
                    localStorage.setItem('username', response.data.username);
                    localStorage.setItem('adminName', response.data.firstName + ' ' + response.data.lastName);
                    localStorage.setItem('role', JSON.stringify(response.data.permissions));
                    this.router.navigate([this.uiService.getFirstRoute()]);
                }, error => { 
                    this.btnDisabled = false;
                });
        }
    }

}
