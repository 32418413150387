import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';

// operators
import { LoaderService } from '../services/common/loader-service';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class AuthenticationInterceptor implements HttpInterceptor {

  private requests: HttpRequest<any>[] = [];

  constructor(
    private router: Router,
    private loaderService: LoaderService,
    private toastrService: ToastrService,
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    var index = this.loaderService.backGroundUrls.indexOf(req.url.toString());
    if (index < 0) {
      this.requests.push(req);
      this.loaderService.show();
    } else {
      this.loaderService.backGroundUrls.splice(index, 1);
    }
    let token = localStorage.getItem('awr_token');
    if (token) {
      req = req.clone({
        setHeaders: {
          authorization: `bearer ${token}`
        }
      });
    }

    return next.handle(req).pipe(tap((event: HttpEvent<any>) => {
      if (event instanceof HttpResponse) {
        this.removeRequest(req);
      }
    },
      (err: any) => {
        this.removeRequest(req);
        if (err instanceof HttpErrorResponse) {
          if (err.status === 401) {
            localStorage.clear();
            this.router.navigate(['/signin']);
          } else if (err.status === 0) {
            this.toastrService.error('Network Issue!');
            return;
          }
          this.toastrService.error(err.error.message);
        }
      }));
  }

  removeRequest(req: HttpRequest<any>) {
    const i = this.requests.indexOf(req);
    this.requests.splice(i, 1);
    if (this.requests.length == 0) {
      this.loaderService.hide();
    }
  }

}
