import {Component, OnInit, ElementRef} from '@angular/core';
import {Router, ActivatedRoute, NavigationEnd} from '@angular/router';
import {Subscription} from 'rxjs';
import {filter} from 'rxjs/operators';

// services
import {UiService} from '../../../services/common/ui-service';
import {SideBarService} from '../../../services/common/side-bar.service';
import {HttpService} from '../../../services/http/http.service';

// constant
import {apiUrls} from '../../../constant/app.constant';
import {NgbModal, NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import { AllHeadingConstant } from '../../../constant/heading.constant';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

    headingConstant = AllHeadingConstant;
    routeParts: any[];
    routerEventSub: Subscription;

    adminName: string;

    constructor(private activeModal: NgbActiveModal, private element: ElementRef, private router: Router, private uiService: UiService,
                public sideBarService: SideBarService, private http: HttpService) {

        this.routerEventSub = this.router.events.pipe(filter(event => event instanceof NavigationEnd))
            .subscribe((routeChange) => {
                this.routeParts = this.uiService.getRouterParts();
                this.checkPermissions(this.routeParts);
                this.sideBar();
            });

        this.adminName = localStorage.getItem('adminName');
    }

    ngOnInit() {
    }

    private sideBar() {
        const navbar: HTMLElement = this.element.nativeElement;
        this.sideBarService.toggleButton = navbar.getElementsByClassName('navbar-toggler')[0];
        this.sideBarService.sidebarClose();
        this.sideBarService.sidebarVisible = false;
        var $layer: any = document.getElementsByClassName('close-layer')[0];
        if ($layer) {
            $layer.remove();
            this.sideBarService.mobile_menu_visible = 0;
        }
    }

    private checkPermissions(data) {
        if (data.length) {
            if (data.length == 1) {
                if (this.uiService.getPermissions(data[0].title, '')) {
                    return true;
                }
            } else if (data.length > 1) {
                const index = ['Add', 'Edit', 'Detail', 'Posts', 'Comments'].indexOf(data[1].title);
                if (index == -1) {
                    if (this.uiService.getPermissionSubmenu(data[0].title, data[1].title)) {
                        return true;
                    }
                } else if (this.uiService.getPermissions(data[0].title, data[1].title)) {
                    return true;
                }
            }
            this.router.navigate([this.uiService.getFirstRoute()]);
            return false;
        }
    }

    logOut() {
        this.http.postData(apiUrls.logout, {})
            .subscribe(response => {
                this.uiService.clearLocalStorage();
            });
    }

    ngOnDestroy() {
        this.routerEventSub.unsubscribe();
        this.activeModal.dismiss();
    }

}
